@import "src/styles/common/font";
@import "src/styles/common/color";

.container-package {
  background-color: $cl-lbl-5;
  border-radius: 14px;
  padding: 24px;
}

.title-package {
  font-size: 22px;
  font-weight: 600;
  color: $cl-fill-6;
}

.box-package {
  padding: 24px;
  border-radius: 10px;
  background: linear-gradient(to right, #477aff, #22e16f);
  margin-top: 24px;
  position: relative;
}

.item-package {
  font-size: 22px;
  font-weight: 600;
  color: $cl-lbl-5;
}

.body-package {
  display: flex;
  flex-direction: column;
  gap: 4px;
  line-height: 20px;
  margin-top: 8px;
}

.content-package {
  display: flex;
  gap: 4px;
}

.content-package .item {
  font-weight: 400;
  color: $cl-lbl-5;
  opacity: 0.5;
}

.package-item {
  font-weight: 500;
  color: $cl-lbl-5;
}

.package-btn {
  background-color: $cl-lbl-5;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;
  border: none;
  font-weight: 500;
  padding: 9px 12px 9px 12px;
  color: $cl-lbl-1;
}

.container-btn {
  display: flex;
  margin-top: 8px;
  justify-content: end;
  gap: 8px;
}

.title-content {
  font-size: 22px;
  font-weight: 600;
}

.detail-package {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  align-items: center;
}

.btn-view {
  background-color: $cl-lbl-5;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  font-weight: 500;
  padding: 9px 12px 9px 12px;
  color: $cl-fill-6;
}

.card-package {
  background-color: $cl-lbl-5;
  border-radius: 14px;
  padding: 24px;
  margin-top: 16px;
  display: grid;
  align-items: center;
}

.item1 {
  grid-area: 1/1;
}

.item2 {
  grid-area: 2/1;
}

.item3 {
  grid-area: 2/3;
}

.item5 {
  grid-area: 2/2;
  padding: 26px;
}

.title-card {
  font-size: 18px;
  font-weight: 600;
  color: $cl-lbl-1;

  > span {
    font-size: 14px;
    font-weight: 400;
    color: $cl-lbl-6;
    margin-left: 5px;
  }
}

.container-option {
  display: flex;
  flex-direction: column;
}

.body-option {
  font-weight: 400;
  line-height: 20px;
  color: $cl-lbl-2;
  display: flex;
  margin-top: 8px;
  align-items: center;

  > span {
    white-space: break-spaces;
    font-weight: 400;
    color: $cl-lbl-2;
    line-height: 20px;
  }
}

.content-option {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 8px;
  text-align: justify;

  > span {
    white-space: break-spaces;
    font-weight: 400;
    color: $cl-lbl-2;
    line-height: 20px;
  }
  margin-right: auto;
}

.btn-package {
  background-color: $cl-system-success;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  font-weight: 500;
  margin-left: 40px;
  color: $cl-lbl-5;
  padding: 9px 12px 9px 12px;
}

.container-card {
  display: flex;
  align-items: center;
}

.container_free {
  margin-left: auto;
  display: flex;
  gap: 8px;
  align-items: center;
  line-height: 24px;
}

.box-item {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-left: auto;
}

.card-box {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.item-option {
  line-height: 24px;
}

.card-content {
  font-size: 18px;
  font-weight: 600;
  color: $cl-lbl-1;
}

.content-item {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: end;
}

.package-content {
  color: $cl-system-error;
  font-weight: 500;
}

.package-decoration {
  text-decoration: line-through;
  color: $cl-lbl-3;
  font-size: 15px;
  font-weight: 600;
}

.card-item {
  color: $cl-system-success;
  font-size: 18px;
  font-weight: 600;
}

.card-body {
  font-weight: 400;
  font-size: 14px;
  color: $cl-lbl-2;
}

.card-container {
  display: flex;
  align-items: center;
}

.btn-use {
  background-color: $cl-lbl-5;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid $cl-separator;
  font-weight: 500;
  margin-left: 40px;
  padding: 9px 12px 9px 12px;
  color: $cl-lbl-1;
}

.vector {
  position: absolute;
  right: 0px;
  bottom: 89px;
}

.title-use {
  font-size: 15px;
  font-weight: 600;
  margin-top: 24px;
  color: $cl-fill-6;
}

.box-btn {
  display: flex;
  gap: 16px;
}

.body-use {
  background-color: $cl-fill-2;
  border-radius: 10px;
  padding: 16px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 9px;
}

.modal-box {
  display: flex;
  justify-content: space-between;

  .item-use {
    color: $cl-lbl-2;
    font-weight: 400;
  }

  .content-use {
    font-weight: 500;
    color: $cl-fill-6;
  }
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background-color: $cl-system-success;
  border-color: $cl-system-success;
}

.box-radio {
  margin-top: 18px;
}

.radio {
  display: flex;
  gap: 40px;
}

.radio-button {
  margin-top: 16px;
}

.content-body {
  font-weight: 400;
  color: $cl-lbl-2;
  margin-top: 16px;
  font-size: 14px;
}

.btn-option {
  padding: 9px 24px 9px 24px;
  border-radius: 10px;
  border: 1px solid $cl-separator;
  background-color: $cl-lbl-5;
  color: $cl-lbl-2;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  line-height: 24px;
}

.btn-option-disable {
  color: $cl-lbl-4;
}

.btn-option-selected {
  border: 1px solid $cl-primary;
  color: $cl-primary;
}

.btn-option:active {
  color: $cl-primary;
  border: 1px solid $cl-primary;
}

.box-option {
  display: grid;
  gap: 8px;
  margin-top: 8px;
  grid-template-columns: 1fr 1fr 1fr;
}

.container-success {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item-success {
  margin-top: 7px;
  font-size: 18px;
  font-weight: 600;
}

.title-item {
  font-weight: 600;
  font-size: 15px;
}

.card-form {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.card-input {
  padding: 10px 16px 10px 16px;
  border-radius: 10px;
}

.item-domain {
  font-size: 13px;
  font-weight: 300;
  color: $cl-lbl-2;
}

.ant-form-item {
  margin-bottom: 0px;
}

.ant-input-group .ant-input {
  padding: 10px 16px 10px 16px;
}

.modal-item {
  font-size: 14px;
  font-weight: 400;
  color: $cl-lbl-2;
  margin-top: 4px;
}

.ant-modal .title {
  line-height: 0px;
}

.ant-modal .ant-modal-title {
  line-height: 0px;
}

.package-container {
  display: flex;
  gap: 8px;
  align-items: center;
}

.package-use {
  color: $cl-system-process;
  font-size: 14px;
  font-weight: 400;
}

.box-success {
  color: $cl-lbl-1;
  font-size: 14px;
  font-weight: 400;
  margin-top: 24px;
}

.status-pkg-expireSoon {
  color: $cl-system-warning;
  margin-left: 8px;
}

.status-pkg-expired {
  color: $cl-system-error ;
  margin-left: 8px;
}

.btn-search {
  background-color: $cl-primary;
}

@media (max-width: 768px) {
  .container-package {
    padding: 16px;
  }

  .container_free {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .title-card {
    display: flex;
    align-items: center;
  }

  .btn-package {
    margin-left: auto;
  }

  .content-option {
    > span {
      line-height: 20px;
      font-size: map-get($font-size, "13");
    }
  }

  .btn-use {
    margin-left: auto;
  }

  .container-card {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .container_free {
    margin-left: 0;
  }

  .package-decoration {
    text-decoration: line-through;
    color: $cl-lbl-3;
    font-size: 12px;
    font-weight: 600;
  }

  .card-item {
    color: $cl-system-success;
    font-size: 14px;
    font-weight: 600;
  }

  .package-content {
    font-size: map-get($font-size, "12");
  }

  .card-body {
    font-size: map-get($font-size, "13");
  }

  .vector {
    bottom: 165px;
  }

  .img-vector {
    width: 106px;
  }

  .container-option {
    display: flex;
    flex-direction: column;
  }

  .box-option {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .content-item {
    margin-left: 0;
    margin-top: 8px;
    gap: 3px;
    justify-content: end;
  }

  .card-content {
    font-size: 13px;
    font-weight: 500;
  }

  .content-package .item {
    font-size: 12px;
  }

  .package-item {
    font-size: 12px;
  }

  .box-package {
    padding: 16px;
    margin-top: 16px;
  }

  .body-option {
    display: block;
  }

  .item-option {
    margin-top: 8px;
    line-height: 15px;
  }

  .card-package {
    display: grid;
  }

  .item1 {
    grid-area: 1/1;
  }

  .item2 {
    grid-area: 2/1;
    grid-column: span 2;
  }

  .item3 {
    grid-area: 3/1;
    grid-column: span 2 !important;
  }

  .item4 {
    grid-area: 1/2;
  }

  .btn-box {
    display: flex;
  }

  .card-box {
    margin-left: auto;
  }
}
