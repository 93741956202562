@use "sass:map";
@import "src/styles/common/font";
@import "src/styles/common/color";

.title-modal {
  border-bottom: 1px solid $cl-separator;
  padding: 12px 24px 12px 24px;
  display: flex;
  align-items: center;
  font-weight: map-get($font-weight, "semi-bold");
  font-size: map-get($font-size, "15");
}

.icon-close {
  cursor: pointer;
  margin-left: auto;
}

.body-modal-card {
  padding: 24px 24px 14px 24px; 
}

.footer-content {
  padding: 24px;
  text-align: right;
  > button {
    margin-left: 16px;
  }
}

.ant-modal .ant-modal-footer {
  text-align: start;
}

.ant-modal .ant-modal-content {
  padding: 0px;
}

.box-btn {
  display: flex;
  gap: 16px;
  justify-content: end;
}

.btn-item {
  background: $cl-fill-1;
  color: $cl-fill-6;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid $cl-separator;
  padding: 11px 24px 11px 24px;
}

.btn-success {
  background: $cl-primary;
  color: $cl-fill-1;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid $cl-separator;
  padding: 11px 24px 11px 24px;
}

.btn-refuse {
  background: $cl-fill-6;
  color: $cl-fill-1;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid $cl-separator;
  padding: 11px 24px 11px 24px;
}
