$cl-pr-dark-3: #1F894A;
$cl-pr-dark-2: #249D54;
$cl-pr-dark-1: #28B15F;
$cl-primary: #2DC56A;
$cl-pr-light-1: #42CA78;
$cl-pr-light-2: #57D087;
$cl-pr-light-3: #6CD696;

$cl-lbl-1: #292D34;
$cl-lbl-2: #77787B;
$cl-lbl-3: #AEAEB7;
$cl-lbl-4: #DDDDE5;
$cl-lbl-5: #FFFFFF;
$cl-lbl-6: #4153F3;

$cl-fill-1: #FFFFFF;
$cl-fill-2: #F3F3F5;
$cl-fill-3: #ECECF0;
$cl-fill-4: #E5E5EB;
$cl-fill-5: #3D4148;
$cl-fill-6: #292D34;

$cl-system-success: #2DC56A;
$cl-system-process: #5464F4;
$cl-system-warning: #FFB55E;
$cl-system-error: #FF6340;
$cl-system-more: #6554C0;
$cl-system-process-2: #1AACFF;
$cl-system-pink: #E64FFE;
$cl-system-brown: #6F5C5E;

$cl-separator: #E7E7ED;
$cl-separator-bg-green: #EAF9F0;
$cl-separator-bg-blue: #F2F6FF;
$cl-separator-bg-yellow: #FFF0E1;
$cl-separator-bg-red: #FFEDE9;
$cl-separator-bg-violet: #EFEDFF;

$cl-gradient: linear-gradient(88.08deg, #22E16F 1.62%, #477AFF 98.38%);
;