@use "sass:map";
@use "src/styles/common/font";
@import "src/styles/common/color";

.reg-buymore {
  > span {
    font-weight: map-get(font.$font-weight, "semi-bold");
    font-size: map-get(font.$font-size, "22");
    color: $cl-fill-6;
  }

  > div {
    margin-top: 4px;
    font-weight: map-get(font.$font-weight, "regular");
    font-size: map-get(font.$font-size, "14");
    color: $cl-lbl-3;
  }

  .sub-footer {
    font-weight: map-get(font.$font-weight, "regular");
    font-size: map-get(font.$font-size, "14");
    margin-top: 24px;
    color: $cl-lbl-2;
  }

  // SUCCESS
  .container-success {
    display: flex;
    flex-direction: column;
    align-items: center;

    .item-success {
      margin-top: 7px;
      font-size: map-get(font.$font-size, "18");
      font-weight: map-get(font.$font-weight, "semi-bold");
      color: $cl-fill-6;
    }

    .content-body {
      font-weight: map-get(font.$font-weight, "regular");
      font-size: map-get(font.$font-size, "14");
      color: $cl-lbl-2;
      margin-top: 24px;
    }
  }

  .user-input-box {
    > div {
      font-weight: map-get(font.$font-weight, "semi-bold");
      font-size: map-get(font.$font-size, "15");
      color: $cl-fill-6;
      margin-top: 10px;
    }

    .card-input-box {
      padding: 10px 10px 10px 10px;
      border-radius: 10px;
    }
  }

  .user-money-box {
    margin-top: 24px;
    > div {
      font-weight: map-get(font.$font-weight, "semi-bold");
      font-size: map-get(font.$font-size, "15");
      color: $cl-fill-6;
      margin-bottom: 8px;
    }

    .box-money {
      background-color: $cl-fill-2;
      padding: 16px;
      border-radius: 10px;

      .rows-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;

        span:nth-child(1) {
          font-weight: map-get(font.$font-weight, "regular");
          font-size: map-get(font.$font-size, "14");
          color: $cl-lbl-2;
        }

        span:nth-child(2) {
          font-weight: map-get(font.$font-weight, "medium");
          font-size: map-get(font.$font-size, "14");
          color: $cl-fill-6;
        }
      }

      .rows-content:nth-child(3)
      {
        margin-bottom: 0px;
      }
    }
  }
}