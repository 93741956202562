@use "sass:map";
@import "src/styles/common/font";
@import "src/styles/common/color";

.container-indicators {
    background-color: $cl-fill-1;
    border-radius: 14px;
    margin-top: 16px;
    > p {
        font-weight: map-get($font-weight, "semi-bold");
        font-size: map-get($font-size, "22");
        padding: 16px 24px 16px 24px;
    }
}