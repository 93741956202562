@use "../../../styles/common/color";
@use "../../../styles/common/font";

.header {
  background-color: color.$cl-lbl-5;
  padding: 18px 56px 18px 56px;
  border-bottom: 1px solid color.$cl-separator;
  justify-content: space-between;
  display: flex;
  align-items: center;

  .comment {
    font-weight: 400;
    font-size: 14px;
    color: color.$cl-lbl-3;
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.img-login {
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  background-color: #8bc6ec;
  background-image: linear-gradient(135deg, #8bc6ec 0%, var(--core) 100%);
}

.form-login {
  padding: 32px;
}

.registerMessage {
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}

.registerMessage .btn-register {
  margin: 20px auto;
  background: inherit;
  border-radius: 25px;
  width: 50%;
  padding: 12px 0;
  color: white;
  cursor: pointer;
  border: 1px solid white;
}

.registerMessage .btn-register:hover {
  color: var(--btn-register);
  border: 1px solid var(--btn-register);
}

.btn-login {
  background-color: var(--Primary);
  padding: 11px 24px 11px 24px;
  width: 100%;
  border-radius: 10px;
  transition: all ease 0.5s;
  color: white;
  cursor: pointer;
  border: none;
  font-size: 15px;
  font-weight: 600;
}

.btn-login:hover {
  background: var(--Primary);
  opacity: 0.5;
}

.container-login {
  // /*background: url("../../public/img/bg-1.jpg");*/
  // background: linear-gradient(180deg, #f3f3f5 0%, #dcffea 100%);
  // /*z-index: -1;*/
  // /*position: absolute;*/
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
  // height: 119vh;

  // width: 100%;
  // /*display: flex;*/
  // /*align-items: center;*/
  // /*justify-content: center;*/
  // position: relative;

  .ellipse-left {
    position: absolute;
    top: 20%;
  }
  .ellipse-top {
    position: absolute;
    top: 0;
    right: 0;
  }
  .ellipse-right {
    position: absolute;
    top: 40%;
    right: 0;
  }
}
.overlay-1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.form-login .form-item {
  position: relative;
  margin-top: 24px;
}

// .form-login .form-item label {
//     // position: absolute;
//     // top: 12px;
//     // left: 15px;
//     // z-index: 900;
//     // opacity: 0.5;
// }

.form-login .form-item label svg {
  font-size: 18px;
}

.form-login .form-item .form-input {
  padding: 12px 16px 12px 16px;
  border-radius: 10px;
  border-color: var(--sep-01);
}

.title {
  font-size: 15px;
  font-weight: 600;
}

.body-form {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.account {
  font-size: 16px;
  font-weight: 400;
}

.register {
  font-size: 15px;
  font-weight: 600;
  color: var(--Primary);
  cursor: pointer;
}

.footer {
  background-color: color.$cl-lbl-5;
  padding: 18px 56px 18px 56px;
  border-top: 1px solid color.$cl-separator;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;

  .footer-text {
    font-size: 13px;
    font-weight: 300;
    color: color.$cl-lbl-2;
  }
}
.form-register{
  position: relative;
}
.loading-container{
background-color: rgba(0, 0, 0, 0.1);
position: absolute;
top: 0;
left:0;
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
z-index: 1000;
opacity: 1;
 
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media (max-width: 768px) {
  .header {
    display: none;
  }

  .footer {
    display: none;
  }

  .container-icon {
    display: none;
  }
}
