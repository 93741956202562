@use "sass:map";
@use "src/styles/common/font";
@import "src/styles/common/color";

.dashboard-home {

  >h2 {
    font-weight: map-get(font.$font-weight, 'semi-bold');
    font-size: map-get(font.$font-size, "28");
  }

  .box-required {
    width: 203px;
    height: 118px;
    margin-top: 24px;
    padding: 24px;
    border-radius: 14px;
    margin-right: 16px;
    background: $cl-fill-1;
    display: inline-block;

    &:hover {
      cursor: pointer;
    }

    > .card-dashboard {
      list-style: none;
      span {
        color: $cl-lbl-5;
      }

      > span:nth-child(1) {
        font-weight: map-get(font.$font-weight, 'regular');
        font-size: map-get(font.$font-size, "11");
        line-height: 14px;
      }

      > span:nth-child(3) {
        font-weight: map-get(font.$font-weight, 'medium');
        font-size: map-get(font.$font-size, "14");
        line-height: 20px;
      }

      div {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > span {
          margin-top: 4px;
          font-weight: map-get(font.$font-weight, 'semi-bold');
          font-size: map-get(font.$font-size, "22");
          line-height: 32px;
        }
      }
    }
  }

  .box-required:nth-child(2) {
    background: linear-gradient(255.63deg, #56D087 0%, #2CBE67 100%);
  }
  .box-required:nth-child(3) {
    background: linear-gradient(75.85deg, #FF985E 0%, #FFC076 100%);
  }
  .box-required:nth-child(4) {
    background: linear-gradient(76.37deg, #AB5BFB 3.73%, #BD95FF 100%);
  }
  .box-required:nth-child(5) {
    background: linear-gradient(75.85deg, #4C93FF 0%, #79AFFF 100%);
  }
  .box-required:nth-child(6) {
    background: linear-gradient(75.89deg, #AB6663 0%, #E8B18F 100%);
  }
}