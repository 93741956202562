@import "./styles/common/_mixin.scss";

* {
  margin: 0;
  font-family: "Plus Jakarta Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --login-card-bg: white;
  --label-01: #292d34;
  --sep-01: #e7e7ed;
  --Primary: #2dc56a;
}

code {
  font-family: "Plus Jakarta Sans", "Courier New", monospace;
}

.hv-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-logout {
  background: red;
}

.tox-promotion,
.tox-statusbar__branding {
  display: none;
}

.container-login-register {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100dvh;
}

.text-title-h3 {
  margin: unset;
}

.ant-tabs-tab {
  color: $cl-lbl-3 !important;
}

.ant-tabs-nav-list .ant-tabs-tab-active {
  > div {
    color: $cl-lbl-1 !important;
  }
}
.ant-tabs-ink-bar {
  height: 2px;
  background: #2dc56a !important;
}

.ant-table-thead .ant-table-cell {
  background-color: $cl-lbl-5 !important;
  font-weight: map-get($font-weight, "semi-bold");
  font-size: map-get($font-size, "15");
  line-height: 24px;
}

//Steps add new user
.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: $cl-system-success;
  border: 2px solid $cl-system-success;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: $cl-fill-1;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 2px;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: $cl-fill-1;
  border: 2px solid $cl-system-success;
}

.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: $cl-system-success;
}

.ant-steps .ant-steps-item-wait .ant-steps-item-icon {
  background-color: $cl-fill-1;
  border: 2px solid $cl-fill-3;
}

.ant-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: $cl-fill-1;
  display: flex;
  justify-content: center;
}

.ant-steps .ant-steps-item-icon {
  width: 20px;
  height: 20px;
}

.ant-steps.ant-steps-label-vertical .ant-steps-item-tail {
  margin-inline-start: 56px;
  padding: 0 3px;
}

.ant-steps .ant-steps-item-tail {
  top: 10px;
}

.ant-steps .ant-steps-item-title {
  line-height: 20px;
}

.ant-steps {
  padding: 0 42px;
}

.ant-steps.ant-steps-label-vertical .ant-steps-item-content {
  width: 109px;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  display: flex;
  justify-content: center;
}

.ant-space-gap-col-small {
  column-gap: 8px;
  border: 1px solid $cl-separator;
  padding: 0 9px;
  border-radius: 8px;
  font-size: 24px;
}

.ant-form-horizontal
  .ant-form-item-label[class$="-24"]
  + .ant-form-item-control,
.ant-form-horizontal
  .ant-form-item-label[class*="-24 "]
  + .ant-form-item-control {
  width: 309px;
}

.ant-form-item .ant-form-item-label > label {
  display: flex;
}
.ant-btn-primary  {
  background-color: $cl-primary;
}
