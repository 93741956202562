@use "sass:map";
@import "src/styles/common/font";
@import "src/styles/common/color";

.container-reset-password {
  background: linear-gradient(180deg, #f3f3f5 0%, #dcffea 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .ellipse-left {
    position: absolute;
    left: 0;
    top: 20%;
  }
  .ellipse-top {
    position: absolute;
    top: 0;
    right: 0;
  }
  .ellipse-right {
    position: absolute;
    top: 40%;
    right: 0;
  }
}

.form-reset-password {
  margin-top: 24px;
}

.forgot-password-card {
  background: white;
  width: 436px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 14px;
  padding: 32px;
}

.reset-password-card {
  background: white;
  width: 436px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 14px;
  padding: 32px;
  text-align: center;

  >.container_reset_password {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin: 24px 0px;
    > p:nth-child(1){
      font-weight: map-get($font-weight, "semi-bold");
      font-size: map-get($font-size, "22");
    }
    > p:nth-child(2){
      font-size: map-get($font-size, "16");
    }
  }

}


.btn_reset_password {
  display: grid;
  grid-template-columns: auto ;
}

.footer_forgot_password {
  margin-top: 24px;
  color: $cl-lbl-2;
}
