@use "sass:map";
@use "src/styles/common/font";
@import "src/styles/common/color";

.dashboard-home {
  > h2 {
    font-weight: map-get(font.$font-weight, "semi-bold");
    font-size: map-get(font.$font-size, "28");
  }

  .box-required {
    width: 203px;
    height: 118px;
    margin-top: 24px;
    padding: 24px;
    border-radius: 14px;
    margin-right: 16px;
    background: $cl-fill-1;
    display: inline-block;

    &:hover {
      cursor: pointer;
    }

    > .card-dashboard-pkg {
      list-style: none;
      display: flex;
      flex-direction: column;
      span {
        color: $cl-fill-5;
        font-weight: map-get(font.$font-weight, "medium");
        line-height: 20px;
      }
      > .content-dashboard {
        display: flex;
        justify-content: space-between;
        margin-top: 4px;
        align-items: center;
        > span {
          font-weight: map-get(font.$font-weight, "semi-bold");
          font-size: map-get(font.$font-size, "22");
          line-height: 20px;
          color: $cl-system-error;
          line-height: 32px;
        }
      }
    }
  }
}
