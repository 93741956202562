@use "sass:map";
@import "src/styles/common/font";
@import "src/styles/common/color";

.body-menu {
  background-color: $cl-fill-1;
}

.box-menu {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .content {
    display: flex;
    gap: 8px;
    align-items: center;
    cursor: pointer;
    padding: 10px 16px 10px 16px;
  }

  .content:hover {
    background-color: $cl-fill-2;
    border-radius: 10px;
  }

  .content.active {
    background-color: $cl-primary;
    border-radius: 10px;
    color: $cl-lbl-5;
  }

  .content-menu {
    font-size: 14px;
    font-weight: map-get($font-weight, "medium");
  }
}

.box-menu.admin {
  .content {
    display: flex;
    gap: 8px;
    align-items: center;
    cursor: pointer;
    padding: 10px 16px 10px 16px;
  }

  .content:hover {
    background-color: $cl-fill-2;
    border-radius: 10px;
  }

  .content.active {
    background-color: $cl-lbl-1 !important;
    border-radius: 10px;
    color: $cl-lbl-5;
  }
}

.box-body-menu.admin {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  .content {
    display: flex;
    gap: 8px;
    align-items: center;
    cursor: pointer;
    padding: 10px 16px 10px 16px;
  }

  .content:hover {
    background-color: $cl-fill-2;
    border-radius: 10px;
  }

  .content.active {
    background-color: $cl-lbl-1 !important;
    border-radius: 10px;
    color: $cl-lbl-5;
  }
}
