@use "sass:map";
@use "src/styles/common/font";
@import "src/styles/common/color";

.container-profile {
  > span {
    font-weight: map-get(font.$font-weight, "semi-bold");
    font-size: map-get(font.$font-size, "22");
  }

  .body-card-profile {
    display: flex;
    justify-content: space-between;
    > .card-profile {
      background-color: $cl-lbl-5;
      border-radius: 14px;
      padding: 24px;

      > .card-body-profile {
        display: flex;
        justify-content: space-between;
        > p {
          font-size: map-get(font.$font-size, "18");
          font-weight: map-get(font.$font-weight, "semi-bold");
          color: $cl-lbl-1;
        }
      }

      > .box-profile {
        margin-top: 16px;
        gap: 8px;
        display: grid;
        grid-template-columns: 93px 1fr;

        > p:nth-child(odd) {
          color: $cl-lbl-2;
        }

        > p:nth-child(even) {
          color: $cl-lbl-1;
          font-weight: map-get(font.$font-weight, "medium");
        }
      }

      > .content-profile {
        margin-top: 16px;
        gap: 8px;
        display: grid;
        grid-template-columns: 133px 1fr;

        > p:nth-child(odd) {
          color: $cl-lbl-2;
        }

        > p:nth-child(even) {
          color: $cl-lbl-1;
          font-weight: map-get(font.$font-weight, "medium");
        }
      }
    }
  }

  > .body-profile {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-top: 16px;

    > .card-profile {
      background-color: $cl-lbl-5;
      border-radius: 14px;
      padding: 24px;
      > .card-body-profile {
        display: flex;
        justify-content: space-between;
        > p {
          font-size: map-get(font.$font-size, "18");
          font-weight: map-get(font.$font-weight, "semi-bold");
          color: $cl-lbl-1;
        }
      }

      > .box-profile {
        margin-top: 16px;
        gap: 8px;
        display: grid;
        grid-template-columns: 93px 1fr;

        > p:nth-child(odd) {
          color: $cl-lbl-2;
        }

        > p:nth-child(even) {
          color: $cl-lbl-1;
          font-weight: map-get(font.$font-weight, "medium");
        }
      }

      > .content-profile {
        margin-top: 16px;
        gap: 8px;
        display: grid;
        grid-template-columns: 133px 1fr;

        > p:nth-child(odd) {
          color: $cl-lbl-2;
        }

        > p:nth-child(even) {
          color: $cl-lbl-1;
          font-weight: map-get(font.$font-weight, "medium");
        }
      }
    }
  }

  .card-package {
    padding: 24px;
    border-radius: 14px;
    background-color: $cl-lbl-5;
    margin-top: 16px;
    display: block;

    > span {
      font-weight: map-get(font.$font-weight, "semi-bold");
      font-size: map-get(font.$font-size, "18");
    }

    //> .box-package {
    //  padding: 24px;
    //  border-radius: 10px;
    //  background: linear-gradient(to right, #477aff, #22e16f);
    //  margin-top: 24px;
    //  position: relative;
    //
    //  > span {
    //    color: $cl-lbl-5;
    //    font-weight: map-get(font.$font-weight, "semi-bold");
    //    font-size: map-get(font.$font-size, "18");
    //  }
    //
    //  .content-use {
    //    margin-top: 8px;
    //    display: flex;
    //    flex-direction: column;
    //    gap: 4px;
    //
    //    > span {
    //      color: $cl-lbl-5;
    //      font-weight: map-get(font.$font-weight, "semi-bold");
    //      font-size: map-get(font.$font-size, "18");
    //    }
    //
    //    > ul {
    //      list-style: none;
    //      padding: 0px;
    //      display: flex;
    //
    //      > p:nth-child(1) {
    //        color: $cl-lbl-5;
    //        opacity: 0.5;
    //      }
    //
    //      > p:nth-child(2) {
    //        font-weight: map-get(font.$font-weight, "medium");
    //        color: $cl-lbl-5;
    //        margin-left: 4px;
    //      }
    //    }
    //  }
    //
    //  > icon {
    //    position: absolute;
    //    right: 0px;
    //    bottom: 89px;
    //  }
    //}

    .card-add-user {
      padding: 24px;
      border-radius: 14px;
      margin-top: 16px;
      border: 1px solid $cl-separator;

      > span {
        color: $cl-fill-6;
        font-weight: map-get(font.$font-weight, "semi-bold");
        font-size: map-get(font.$font-size, "18");
      }

      > .add-package {
        color: $cl-fill-6;
      }

      .content-use {
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        gap: 4px;

        > ul {
          list-style: none;
          padding: 0px;
          display: flex;

          > p:nth-child(1) {
            color: $cl-lbl-2;
          }

          > p:nth-child(2) {
            font-weight: map-get(font.$font-weight, "medium");
            color: $cl-lbl-1;
            margin-left: 4px;
          }
        }
      }

      > .btn-change {
        display: flex;
        justify-content: end;
      }

      .content-deployment {
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        gap: 4px;

        .desc-service {
          white-space: break-spaces;
        }

        > p:nth-child(1) {
          color: $cl-lbl-2;
        }

        > p:nth-child(n + 2) {
          font-weight: map-get(font.$font-weight, "medium");
          color: $cl-lbl-1;
        }

        > ul {
          list-style: none;
          padding: 0px;
          display: flex;

          > p:nth-child(odd) {
            color: $cl-lbl-2;
          }

          > p:nth-child(even) {
            font-weight: map-get(font.$font-weight, "medium");
            color: $cl-lbl-1;
            margin-left: 4px;
          }
        }
      }
    }
  }

  .table-content {
    margin-top: 40px;
    > span {
      font-weight: map-get(font.$font-weight, "semi-bold");
      color: $cl-lbl-1;
      font-size: map-get(font.$font-size, "22");
    }
  }
  .content-column {
    color: $cl-lbl-2;
  }

  .item-column {
    display: flex;
    flex-direction: column;
  }
}

.title-info {
  font-weight: map-get(font.$font-weight, "semi-bold");
  font-size: map-get(font.$font-size, "15");
  color: $cl-lbl-1;
}

.body-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

@media (max-width: 768px) {
  .content-profile {
    grid-template-columns: 112px 1fr !important;
  }

  .container-profile {
    > .body-profile {
      > .card-profile {
        padding: 16px;
        > .box-profile {
          grid-template-columns: 82px 1fr;
          > p:nth-child(odd) {
            font-size: 12px;
          }

          > p:nth-child(even) {
            font-size: 12px;
          }
        }

        > .content-profile {
          > p:nth-child(odd) {
            font-size: 12px;
          }

          > a {
            font-size: 12px;
          }

          > p:nth-child(even) {
            font-size: 12px;
          }
        }
      }
    }

    .card-package {
      padding: 16px;

      > .card-add-user {
        padding: 16px;
      }
    }
  }
}
