@use "sass:map";
@import "src/styles/common/font";
@import "src/styles/common/color";

.container-revenue {
  background-color: $cl-fill-1;
  border-radius: 14px;
  margin-top: 16px;

  > p {
    font-weight: map-get($font-weight, "semi-bold");
    font-size: map-get($font-size, "22");
    padding: 16px 24px 16px 24px;
  }

  table {
    width: 100%;
    border-collapse: collapse; /* Gộp đường viền các ô */
    margin-top: 8px;
  }

  td {
    border: 1px solid $cl-separator;
    padding: 24px;
    border-left: none;
    border-right: none;
    border-bottom: none;
    > .container-column {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .column-revenue:nth-child(1) {
        display: flex;
        flex-direction: column;
        gap: 4px;

        > span:nth-child(2) {
          font-weight: map-get($font-weight, "semi-bold");
          font-size: map-get($font-size, "18");
        }
      }

      .column-revenue:nth-child(2) {
        display: flex;
        flex-direction: column;
        gap: 4px;

        > .column-up-revenue {
          display: flex;
          align-items: center;
          margin-left: auto;
          gap: 4px;
          > span {
            font-weight: map-get($font-weight, "medium");
            color: $cl-system-success;
          }
        }

        > .column-down-revenue {
            display: flex;
            align-items: center;
            margin-left: auto;
            gap: 4px;
            > span {
              font-weight: map-get($font-weight, "medium");
              color: $cl-system-error;
            }
          }

          > .column-equal-revenue {
            display: flex;
            align-items: center;
            margin-left: auto;
            gap: 4px;
            > span {
              font-weight: map-get($font-weight, "medium");
            }
          }

        > span {
          font-size: map-get($font-size, "12");
          color: $cl-lbl-2;
        }
      }
    }
  }

  td:nth-child(2) {
    border-left: 1px solid $cl-separator;
    padding: 24px;
  }
}
