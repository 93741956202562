.title-sp {
  font-size: 22px;
  font-weight: 600;
}

.title-box {
  font-size: 22px;
  font-weight: 600;
  margin-top: 40px;
}

.container-chat {
  background-color: #ffffff;
  border-radius: 14px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
}

.content-comment {
  font-size: 18px;
  font-weight: 600;
  margin-top: 16px;
  line-height: 24px;
}

.box-content {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  color: #77787b;
  line-height: 20px;
  margin-top: 4px;
}

.body-card {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 16px;
}

.time-slot {
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-top: 4px;
}

.btn-chat {
  background-color: #292d34;
  padding: 11px 24px 11px 24px;
  width: 100%;
  border-radius: 10px;
  color: white;
  border: none;
  font-size: 15px;
  font-weight: 600;
  margin-top: 92px;
  cursor: pointer;
}

.box-chat {
  background-color: #ffffff;
  border-radius: 14px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
}

.user-manual {
  font-size: 15px;
  font-weight: 600;
}

.btn-detail {
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #e7e7ed;
  font-size: 14px;
  font-weight: 500;
  color: #292d34;
  margin-top: 22px;
  padding: 9px 12px 9px 12px;
  cursor: pointer;
}

.btn-phone {
  background-color: #ffffff;
  padding: 11px 24px 11px 24px;
  width: 100%;
  border-radius: 10px;
  transition: all ease 0.5s;
  color: white;
  cursor: pointer;
  border: none;
  font-size: 15px;
  font-weight: 600;
  margin-top: 40px;
  border: 1px solid #e7e7ed;
  color: #292d34;
}

.btn-phone-2 {
  background-color: #ffffff;
  padding: 11px 24px 11px 24px;
  width: 100%;
  border-radius: 10px;
  transition: all ease 0.5s;
  color: white;
  cursor: pointer;
  border: none;
  font-size: 15px;
  font-weight: 600;
  margin-top: 16px;
  border: 1px solid #e7e7ed;
  color: #292d34;
}

.box-card {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 16px;
}

@media (max-width: 768px) {
  .box-card {
    gap: 0;
  }
 }