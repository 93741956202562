@import "src/styles/common/color";

@mixin btn-property($color) {
  background: $color;
}

@mixin btn-customize-44 {
  height: 44px;
  padding: 10px 24px;
  border-radius: 10px;
  border: 0;
  cursor: pointer;
}
@mixin btn-customize-32 {
  height: 32px;
  padding: 6px 12px;
  border-radius: 8px;
  border: 0;
  cursor: pointer;
}

.btn-customize-44-green {
  @include btn-customize-44;
  @include btn-property($cl-primary);
  color: $cl-lbl-5;
}
.btn-customize-44-black {
  @include btn-customize-44;
  @include btn-property($cl-fill-6);
  color: $cl-lbl-5;
}
.btn-customize-44-grey {
  @include btn-customize-44;
  @include btn-property($cl-fill-2);
}
.btn-customize-44-outline {
  @include btn-customize-44;
  @include btn-property($cl-fill-1);
  color: $cl-lbl-1;
  border: 1px solid #E7E7ED;
}
.btn-customize-44-border-empty {
  @include btn-customize-44;
  @include btn-property($cl-fill-1);
}

.btn-customize-44-white {
  @include btn-customize-44;
  @include btn-property($cl-fill-1);
}

.btn-customize-32 {
  @include btn-customize-32;
  @include btn-property($cl-primary);
}

.btn-customize-32-green {
  @include btn-customize-32;
  @include btn-property($cl-primary);
  color: $cl-lbl-5;
}
.btn-customize-32-black {
  @include btn-customize-32;
  @include btn-property($cl-fill-6);
  color: $cl-lbl-5;
}
.btn-customize-32-grey {
  @include btn-customize-32;
  @include btn-property($cl-fill-2);
}
.btn-customize-32-outline {
  @include btn-customize-32;
  @include btn-property($cl-fill-1);
  color: $cl-lbl-1;
  border: 1px solid #E7E7ED;
}
.btn-customize-32-border-empty {
  @include btn-customize-32;
  @include btn-property($cl-fill-1);
}

.btn-customize-32-white {
  @include btn-customize-32;
  @include btn-property($cl-fill-1);
}

.btn-customize-44-red {
  @include btn-customize-44;
  @include btn-property($cl-system-error);
  color: $cl-lbl-5;
}