@use "sass:map";
@import "src/styles/common/font";
@import "src/styles/common/color";

.container-body-modal {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
  > .card-body-modal {
    padding: 16px;
    display: flex;
    flex-direction: column;
    border: 1px solid $cl-separator;
    border-radius: 10px;

    > span {
      font-weight: map-get($font-weight, "semi-bold");
      font-size: map-get($font-size, "15");
    }

    > ul {
      list-style: none;
      padding: 0;
      display: grid;
      grid-template-columns: 140px 1fr;

      > li:nth-child(1) {
        color: $cl-lbl-2;
        margin-top: 8px;
      }

      > li:nth-child(2) {
        color: $cl-fill-6;
        margin-top: 8px;
        font-weight: map-get($font-weight, "medium");
      }
    }
  }
}

//Modal Info user

.info-user {
  margin-top: 24px;
}

//Modal success

.modal-add {
  display: flex;
  flex-direction: column;
  gap: 4px;

  > .body-add {
    display: flex;
    flex-direction: column;
    align-items: center;
    > span {
      font-size: 18px;
      font-weight: 600;
      color: $cl-fill-6;
    }
  }

  > .card-body-modal {
    padding: 16px;
    display: flex;
    flex-direction: column;
    border: 1px solid $cl-separator;
    border-radius: 10px;
    margin-top: 24px;

    > span {
      font-weight: map-get($font-weight, "semi-bold");
      font-size: map-get($font-size, "15");
    }

    > ul {
      list-style: none;
      padding: 0;
      display: grid;
      grid-template-columns: 140px 1fr;

      > li:nth-child(1) {
        color: $cl-lbl-2;
        margin-top: 8px;
      }

      > li:nth-child(2) {
        color: $cl-fill-6;
        margin-top: 8px;
        font-weight: map-get($font-weight, "medium");
      }
    }
  }
}


// Modal lock user

