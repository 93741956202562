
.wrap-header-extension {
    display: flex;
    flex-direction: column;
    text-align: center;

}

.extension-text-head {
    color: white;
    font-weight: bold;
    font-size: 24px;
    text-transform: uppercase;
}

.wrap-header-extension p {
    color: white;
    font-size: 16px;
}

.search-extension {
    width: 50%;
    margin: 15px auto;
}

.tags-extension {
    display: flex;
    justify-content: center;
    width: 80%;
    margin: 0px auto;
}

.search-extension-box {

}

.list-plugin {
    margin-top: 24px;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    gap: 20px;
}

:root {
    --primary-color: #0ea5e9;
    --secondary-color: #f8fafc;
    --accent-color: #020617;
    --box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    --white: #FFFFFF;
}

.card-extension {
    box-shadow: var(--box-shadow);
    background: var(--white);
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    margin-bottom: 2rem;
    width: 380px;
    flex-direction: column;
    border-radius: 1rem;
    /*height: 243px;*/
    padding: 1.5rem;
}

header {
    display: flex;
    flex-direction: column;
    width: 60%;
    overflow: hidden;
}

.card-extension .wrap-header {
    display: flex;
    justify-content: space-between;
}

.wrap-header img {
    object-fit: contain;
    width: 110px;
    height: 50px;
    mix-blend-mode: multiply;


}

.title {
    font-size: 16px;
    line-height: 1;
    color: black;
    font-weight: bold;
    max-width: 250px;
    overflow: hidden;
}

.price {
    font-size: 14px;
    line-height: 2rem;
    max-width: 250px;
    overflow: hidden;
    color: black;
}

.desc {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    line-height: 1.625;
    text-wrap: pretty;
    font-weight: 300;
    color: black;
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}


.action {
    margin-top: auto;
}

.system-config-zms {
    border: 1px solid var(--secondary-color);
    padding: 24px 16px;
    background: var(--secondary-color);
    border-radius: 16px;
}

.tabs-zms {
    width: 100%;
}

.tab-items {
    gap: 20px
}

.tab-item {
    padding: 12px;
    width: 47%;
    display: flex;
    flex-direction: column;
    gap: 8px
}

.tab-item label {
    font-weight: bold;
}

.tab-item:nth-child(2) {
    width: 52%;
}

.highlight {
    font-weight: bold; /* In đậm */
}

.preview {
    border-radius: 16px;
    padding: 24px 16px;
    box-shadow: var(--box-shadow);
}

.preview-title {
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 7px;
}

.wrap-form-zms {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 12px 0;
}

.item-form-zms {
    width: 100%;

}

.line-form-zms {
    width: 100px;
    text-align: center;
    align-items: center;
    display: flex;
}

.item-form-zms-select {
    width: 350px;
}

.btn-extension {
    border: unset;
    background: rgb(45, 197, 106);
    background: linear-gradient(90deg, rgba(45, 197, 106, 1) 60%, rgba(141, 232, 178, 1) 100%);
    padding: 9px 12px 9px 12px;
    border-radius: 8px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: var(--white);
    transition: all 0.3s ease-out;
}

.btn-green:hover, .btn-crm:hover, .btn-extension:hover {
    opacity: 0.7;
    cursor: pointer;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.btn-disabled {
    background: rgba(233, 240, 245, 0.88);
    color: black;

}
.actions-buy-more-admin{
    display: flex;
    margin-top: auto;
    gap: 8px;
}