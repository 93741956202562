.input-create{
    display: flex;
    gap:10px;
}
.select-data-actions{
    display: flex;
    justify-content: space-between;
}
.select-data-action-butons{
    display: flex;
    gap: 10px;
}
.ant-select-selection-item .select-data-action-butons{
    display: none;
}
.Modal-custom .ant-modal-content{
    padding:24px !important;

}
.Modal-custom-content{
    padding:24px;
    margin-top: 24px;
}