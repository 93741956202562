@use "sass:map";
@import "src/styles/common/font";
@import "src/styles/common/color";

.forgot-password-card {
  background: white;
  width: 436px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 14px;
  padding: 32px;
}

.btn_forgot_password {
  display: grid;
  grid-template-columns: auto auto;
  gap: 16px;
}

.footer_forgot_password {
  margin-top: 24px;
  color: $cl-lbl-2;
}
