@import "../../../../styles/common/color";
@import "../../../../styles/common/font";

// Tabs

.reg-register {
  font-size: 28px;
  font-weight: 600;
}

.reg-sub-root {
  background: $cl-lbl-5;
  margin-top: 24px;
  border-radius: 14px;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  padding: 8px 24px 0px 24px;
}

.ant-tabs-top > .ant-tabs-nav {
  margin: 0px;
}

// Table
.reg-table {
  padding: 8px 24px 0px 24px;
}

.ant-table-wrapper .ant-table-container {
  border-start-start-radius: 0px;
  border-start-end-radius: 0px;
}

.ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > :last-child {
  border-start-end-radius: 0px;
}

.ant-table-wrapper .ant-table-pagination-right {
  justify-content: center;
}

.ant-table-wrapper .ant-table {
  border-radius: 14px;
}

.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container {
  border-inline-start: none;
  border-inline-end: none;
}

.ant-table-wrapper .ant-table.ant-table-bordered {
  border-inline-end: none;
}

.layout-package {
  padding: 16px 24px 16px 24px;
  display: flex;
  align-items: center;

  > p1 {
    font-size: 14px;
    color: $cl-fill-6;
    font-weight: 400;
  }

  > p2 {
    font-size: 14px;
    color: $cl-primary;
    font-weight: 500;
    margin-left: 4px;
    margin-right: auto;
  }

  > button {
    padding: 9px 12px 9px 12px;
    border-radius: 8px;
    color: $cl-fill-2;
    border: none;
    font-weight: 500;
    color: $cl-lbl-1;
    margin-left: 8px;
    cursor: pointer;
  }
}

.account-status {
  color: $cl-primary;
}

.package-status {
  padding: 4px 10px 4px 10px;
  background-color: $cl-fill-3;
  border-radius: 22px;
  font-size: 12px;
  color: $cl-lbl-1;
}

.sub-package {
  padding: 4px 10px 4px 10px;
  background-color: $cl-separator-bg-yellow;
  border-radius: 22px;
  font-size: 12px;
  color: $cl-lbl-1;
}

// Modal

.ant-modal .ant-modal-header {
  margin-bottom: 0px;
}

.item-modal {
  display: flex;
  flex-direction: column;
  gap: 18px;

  > ul {
    list-style: none;
    display: flex;
    padding: 0px;
    align-items: center;

    > li:nth-child(1) {
      margin-right: auto;
      font-weight: 400;
      color: $cl-lbl-2;
    }

    > li:nth-child(2) {
      font-weight: 500;
      > span {
        padding: 4px 10px 4px 10px;
        background-color: $cl-separator-bg-yellow;
        border-radius: 22px;
      }
    }
  }
}

.footer-modal {
  display: flex;
  margin-left: auto;
  gap: 16px;
  margin-top: 48px;
  > .button1 {
    padding: 11px 24px 11px 24px;
    border-radius: 10px;
    border: 1px solid $cl-separator;
    background-color: $cl-lbl-5;
    font-size: 15px;
    font-weight: 600;
    color: $cl-fill-6;
    margin-left: auto;
    cursor: pointer;
  }

  > .button2 {
    padding: 11px 24px 11px 24px;
    border-radius: 10px;
    border: none;
    background-color: $cl-fill-2;
    font-size: 15px;
    font-weight: 600;
    color: $cl-fill-6;
    cursor: pointer;
  }

  > .button3 {
    padding: 11px 24px 11px 24px;
    border-radius: 10px;
    border: none;
    background-color: $cl-primary;
    font-size: 15px;
    font-weight: 600;
    color: $cl-lbl-5;
    cursor: pointer;
  }
}

.modal-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  > span {
    font-size: 18px;
    font-weight: 600;
    color: $cl-fill-6;
  }
}

.modal-refuse {
  > ul:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    padding: 0;
    list-style: none;

    > li {
      font-size: 18px;
      font-weight: 600;
      color: $cl-fill-6;
    }
  }

  > ul:nth-child(2) {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 24px;
    padding: 0;
    list-style: none;

    > span {
      font-size: 14px;
      font-weight: 500;
      color: $cl-fill-6;
    }
  }
}

.reason-refuse {
  display: flex;
  flex-direction: column;
  > ul {
    list-style: none;
    padding: 0px;

    > li {
      font-size: 15px;
      font-weight: 600;
      color: $cl-fill-6;
    }
  }
}
