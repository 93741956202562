@use "sass:map";
@import "src/styles/common/font";
@import "src/styles/common/color";

.column-pkg {
  display: flex;
  justify-content: space-between;
  align-items: center;

  > .column-content-up {
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 500;
    color: $cl-system-success;
  }

  > .column-content-dow {
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 500;
    color: $cl-system-error;
  }
}

.column-content-ratio {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;

  >.column-content-bar {
    width: 100%;
    background-color: #e0e0e0; 
    border-radius: 8px;
    margin-right: auto;
    
    >.bar {
      height: 6px; 
      // background: linear-gradient(to right, #477aff, #22e16f); 
      border-radius: 8px; 
    }
  }

  >span {
    color: $cl-lbl-2;
    margin-left: auto;
  }
  
}
