@use "sass:map";
@import "src/styles/common/font";
@import "src/styles/common/color";

.column-pkg {
  display: flex;
  justify-content: space-between;
  align-items: center;

  > .column-content-change {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
