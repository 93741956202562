
.navbar-container {
  background: #ffffff;
  width: 100%;
  border-bottom: 1px solid #E7E7ED;
  padding: 0 56px;
}

.navbar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .logo {
    height: 52px;
  }
}

.header-menu-navbar {
  padding: 0 14px 0 40px;
  width: 100%;
  height: 100%;
}

.menu-header {
  background: #ffffff;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 100%;
  list-style-type: none;
}

.menu-item {
  padding: 0 32px;
  height: 100%;
  text-align: center;
  list-style: none;
  display: flex;
  align-items: center;
  color: var(--menu-text);
  font-weight: 400;
  font-size: 1.2em;
  text-transform: initial;
  text-decoration: none;
  white-space: nowrap;

  line-height: 24px;
  margin: 0;
}

.menu-item.active {
  background: #f3f0fd;
  border-bottom: 2px solid #673de6;
  color: #673de6;
}

.menu-item:hover {
  background: #f6f7f8;
  cursor: pointer;
}

.header-icons {
  color: var(--core);
}

.items {
  display: flex;
  justify-content: center;
  gap: 12px;
  align-items: center;
}

.icon-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.icon-item .icon {
  border-radius: 100%;
  background: white;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
}

.icon-item .icon-title {
  color: #292D34;
  font-weight: 600;
  font-size: 15px;
}

.wrap-btn-authen {
  display: flex;
  align-items: center;
  gap: 15px;
}

.buttonHeader {
  border-radius: 15px;
  position: relative;
  border: 0;
  transition: 0.5s;
  z-index: 1;
  min-width: 1.5rem;
  padding: 4px 1rem;
  font-size: 0.875rem;
  line-height: 1;
  height: 60%;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  background: var(--black);
  color: var(--white);
}

.buttonHeader:before,
.buttonHeader:after {
  content: "";
  position: absolute;
  z-index: -1;
}

.buttonHeader.--shine {
  overflow: hidden;
}

.buttonHeader.--shine:after {
  height: 100%;
  width: 0;
  left: -38%;
  top: 0;
  background: var(--primary);
  transform: skew(50deg);
  transform-origin: top left;
  transition: 0.5s;
}

.buttonHeader.--shine:hover:after {
  width: 125%;
}

.btn-core {
  opacity: 0.6;
  transition: all ease 0.3s;
  background: #673de6;
  padding: 5px 10px;
  color: white;
  border-radius: 13px;
  cursor: pointer;
  border: none;
}

.btn-core:hover {
  opacity: 1;
}

.back-ground {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background-color: #e7e7ed;
}

.intro {
  font-weight: 600;
  font-size: 15px;
  color: #77787B;
}

.form-change-password {
  display: flex;
  flex-direction: column;
  gap: 11px;
}


@media (max-width: 768px) {

  .navbar-container {
    background: #ffffff;
    width: 100%;
    border-bottom: 1px solid #E7E7ED;
    padding: 0 16px;
  }

  .navbar {
    .logo {
      height: 56px;
    }
  }

  .logo-responsive {
    width: 105px;
  }
}