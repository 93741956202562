@use "sass:map";
@import "src/styles/common/font";
@import "src/styles/common/color";
@import "../styles/common/variable";

.layout-app {
  border-radius: 8px;
  overflow: hidden;
  //width: calc(50% - 8px);
  //max-width: calc(50% - 8px);
}

.header-layout-app {
  text-align: center;
  color: #fff;
  height: $topbar-height;
  padding: unset;
  line-height: 70px;
  position: fixed;
  top: 0;
  width: 100%;
}

.button-menu {
  display: block;
  background: #ffffff;
  top: 0px;
  z-index: 20;
  position: absolute;
  left: 0px;
  width: 41;
  border-bottom: 1px solid $cl-separator;
  border-top: none;
  border-right: 1px solid $cl-separator;
  border-left: none;
  padding: 16px;
  border-radius: 0px 8px 8px 0px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.button-item {
  display: block;
  background: #ffffff;
  top: 0px;
  z-index: 20;
  position: absolute;
  left: 80px;
  width: 41;
  border-bottom: 1px solid $cl-separator;
  border-top: none;
  border-right: 1px solid $cl-separator;
  border-left: none;
  padding: 16px;
  border-radius: 0px 8px 8px 0px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.button-collapsed {
  left: 264px; /* Vị trí khi menu thu gọn */
}

.sidebar-layout-app {
  width: 100%;
  height: 100%;
  margin-top: $topbar-height-adjust;
  padding: 24px;
}

.body-layout {
  display: flex;
}

.body-content-layout {
  overflow: auto;
  height: calc(100vh - ($topbar-height-adjust));
  width: 100%;
  margin: $topbar-height-adjust 0 0 $sidebar-width;
  padding: 56px 56px 52px 56px;
}

@media (max-width: 768px) {

  .header-layout-app {
    z-index: 20;
  }

  .sidebar-layout-app {
    display: block;
    z-index: 20;
    padding: 0px;
  }

  .body-content-layout {
    overflow: auto;
    height: auto;
    width: 100%;
    margin: 132px 16px 56px 16px !important;
    padding: 0px;
  }

  .button-menu {
    display: block;
    background: #ffffff;
    top: 0px;
    z-index: 20;
    position: absolute;
    left: 0px;
    width: 41;
    border-bottom: 1px solid $cl-separator;
    border-top: none;
    border-right: 1px solid $cl-separator;
    border-left: none;
    padding: 16px;
    border-radius: 0px 8px 8px 0px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  .button-item {
    left: 0px;
  }

  .button-collapsed {
    left: 264px; /* Vị trí khi menu thu gọn */
  }
}
