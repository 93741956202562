@use "sass:map";
@import "src/styles/common/font";
@import "src/styles/common/color";

.user-management-modal {
  min-width: 400px;

  .package-user-management {
    .row-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .pkg-active {
        background-color: $cl-separator-bg-blue;
        color: $cl-system-process;
      }
      > p {
        font-size: map-get($font-size, "12");
        background-color: $cl-separator-bg-blue;
        padding: 4px 10px 4px 10px;
        border-radius: 22px;
        color: $cl-system-process;
      }
    }

    .row-info:last-child {
      margin-bottom: 0;
    }
  }

  .lock-user-management {
    > span {
      font-weight: map-get($font-weight, "semi-bold");
      font-size: map-get($font-size, "15");
      color: $cl-lbl-1;
    }

    table {
      width: 100%;
      border-collapse: collapse; /* Gộp đường viền các ô */
      margin-top: 8px;
    }

    td {
      border: 1px solid $cl-separator;
      padding: 16px;
    }

    td:nth-child(2) {
      border-left: 1px solid $cl-separator;
      font-weight: map-get($font-weight, "medium");
      > p {
        color: $cl-system-process;
      }
    }

    // table td,
    // table th {
    //   border-top: 1px solid $cl-separator;
    //   padding: 12px 16px;
    // }
  }
}

//Add New Customer

.customer-title {
  font-weight: map-get($font-weight, "semi-bold");
  font-size: map-get($font-size, "15");
}

  .service-user-management {
    .service-box-item {
      border: 1px solid $cl-separator;
      border-radius: 10px;
      padding: 16px;
      margin-bottom: 16px;

      .box-service-info {
        display: grid;
        grid-template-columns: 1fr 0.5fr;
        align-items: start;
        justify-content: space-between;

        >.service-status-activated {
          margin-left: auto;
        }
      }

      .box-content {
        margin-top: 16px;
        div {
          text-align: left;
          margin-bottom: 8px;
          color: $cl-lbl-2;
        }

        div:last-child {
          margin-bottom: 0px;
        }

        span {
          font-weight: map-get($font-weight, "regular");
          font-size: map-get($font-size, "14");
          color: $cl-lbl-1
        }
      }
    }
    .service-name {
      font-weight: map-get($font-weight, "semi-bold");
      font-size: map-get($font-size, "15");
      color: $cl-lbl-1
    }

    .service-status-activated {
      padding: 4px 10px 4px 10px;
      border-radius: 22px;
      font-size: 12px;
      background-color: $cl-separator-bg-blue;
      color: $cl-system-process;
    }
  }

// Table Component

.table-user-column {
  display: flex;
  flex-direction: column;
  gap: 8px;

  > p{
    font-weight: map-get($font-weight, $key: "medium");
    color: $cl-system-process;
    cursor: pointer;
  }

  > span:nth-child(1) {
    font-size: map-get($font-size, "15");
  }
  > .table-box {
    display: flex;
    gap: 8px;
    > span {
      font-size: map-get($font-size, "12");
      color: $cl-lbl-2;
    }
  }
}

.row-table-user {
  cursor: pointer;
}