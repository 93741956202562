@use "sass:map";
@import "src/styles/common/font";
@import "src/styles/common/color";

.container-service {
  > .add-service {
    display: flex;
    align-items: center;
    > span {
      font-weight: map-get($font-weight, "semi-bold");
      font-size: map-get($font-size, "28");
      margin-right: auto;
    }

    .service-user {
      font-size: map-get($font-size, "22") !important;
    }
  }
  > .box-service {
    display: flex;
    background-color: $cl-lbl-5;
    border-radius: 14px;
    padding: 16px 24px 16px 24px;
    margin-top: 24px;
    gap: 24px;
    overflow-y: hidden;

    > .btn-service {
      background-color: #ffffff;
      border: 1px solid #cccccc;
      padding: 10px 20px;
      cursor: pointer;
      margin-right: 10px;
    }

    .btn-service.active {
      background-color: red;
      color: white;
    }
  }

  .card-service {
    padding: 24px;
    border-radius: 14px;
    background-color: $cl-lbl-5;
    margin-top: 16px;

    > .title-service {
      display: flex;
      align-items: center;
      gap: 8px;

      .subtitle-service {
        flex: 1;

        span:nth-child(1) {
          margin-right: auto;
          font-weight: map-get($font-weight, "semi-bold");
          font-size: map-get($font-size, "18");
        }

        span:nth-child(2) {
          font-weight: map-get($font-weight, "regular");
          font-size: map-get($font-size, "14");
          color: $cl-lbl-6;
          margin-left: 7px;
        }
      }
    }

    > ul {
      padding: 0px;
      list-style: none;
      margin-top: 8px;
      display: flex;
      flex-direction: column;
      gap: 4px;

      > li:nth-child(1) {
        color: $cl-lbl-2;
      }

      > li:nth-child(2) {
        > span:nth-child(1) {
          font-weight: map-get($font-weight, "semi-bold");
          font-size: map-get($font-size, "15");
          color: $cl-system-process;
        }

        > span:nth-child(2) {
          color: $cl-fill-6;
          margin-left: 8px;
        }
      }
    }
  }

  .buy-more-desc {
    > li {
      white-space: break-spaces;
      margin-bottom: 8px;
    }
  }
}

// Modal

.container-add-service {
  .select-package {
    display: flex;
    flex-direction: column;
    gap: 8px;

    > span {
      font-weight: map-get($font-weight, "semi-bold");
      font-size: map-get($font-size, "14");
    }

    > .custom-radio {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
    }
    
    .custom-radio .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $cl-primary !important;
      border-color: $cl-primary !important;
    }
  }

  .form-item {
    margin-top: 24px;
    gap: 24px;
    display: flex;
    flex-direction: column;
    .form-title {
      font-weight: map-get($font-weight, "semi-bold");
      font-size: map-get($font-size, "14");
    }
  }
}

@media (max-width: 768px) {
   .box-service {
    overflow-x: scroll;
  }
}
