@use "sass:map";
@import "src/styles/common/font";
@import "src/styles/common/color";

.package-modal {
  .package-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-bottom: 10px;

    > ul {
      list-style: none;
      padding: 0px;
      display: flex;
      flex-direction: column;

      > li {
        font-weight: map-get($font-weight, "semi-bold");
        font-size: map-get($font-size, "15");
        margin-bottom: 8px;
      }
    }
  }

  span {
    font-weight: map-get($font-weight, "semi-bold");
    font-size: map-get($font-size, "15");
  }

  .desc-input {
    margin-top: 8px;
  }

  .package-box {
    margin-top: 24px;
    > span {
      font-weight: map-get($font-weight, "semi-bold");
      font-size: map-get($font-size, "15");
    }
  }

  .btn-add {
    display: flex;
    align-items: center;
  }
  
  .display-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .ant-table-content {
    scrollbar-width: thin;
  }
  .ant-card-head-title {
    border-bottom: 1px dashed #e7e7ed;
    padding-bottom: 20px;
  }
  .ant-table-thead > tr > th {
    border: none;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    content: none;
  }
  .ant-table-tbody > tr > td {
    border: none;
    padding: 10px;
  }
  .text-right {
    float: right;
  }
}
