@use "sass:map";
@import "src/styles/common/font";
@import "src/styles/common/color";

.body-content-package {
  > .title-list {
    display: flex;
    align-items: center;
    > span {
      font-weight: map-get($font-weight, "semi-bold");
      font-size: map-get($font-size, "28");
      margin-right: auto;
    }
  }

  > .card-package {
    margin-top: 24px;
    padding: 0;
    border-radius: 14px;
    margin-bottom: 42px;

    > * + * {
      border-top: 1px solid $cl-separator;
    }

    > .card-free {
      padding: 24px;

      .box-free {
        display: flex;
        align-items: center;
        justify-content: space-between;
        > p {
          font-weight: map-get($font-weight, "semi-bold");
          font-size: map-get($font-size, "18");
        }
      }

      > .content-card {
        padding: 0px;
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 3px;
        margin-top: 8px;

        > ul {
          padding: 0px;
          display: flex;
          align-items: center;
          line-height: 20px;
          list-style: none;

          > li:nth-child(1) {
            color: $cl-lbl-2;
          }

          > li:nth-child(2) {
            color: $cl-fill-6;
            font-weight: map-get($font-weight, "semi-bold");
            font-size: map-get($font-size, "15");
            margin-left: 8px;
          }
        }

        > .item-price {
          display: flex;
          align-items: center;
          > span:nth-child(1) {
            color: $cl-lbl-2;
          }

          > span:nth-child(2) {
            text-decoration: line-through;
            color: $cl-lbl-3;
            font-weight: map-get($font-weight, "medium");
            margin-left: 8px;
          }

          > span:nth-child(3) {
            list-style: none;
            font-weight: map-get($font-weight, "semi-bold");
            font-size: map-get($font-size, "15");
            margin-left: 8px;
          }

          > span:nth-child(4) {
            list-style: none;
            font-size: map-get($font-size, "12");
            margin-left: 8px;
            background-color: $cl-separator-bg-red;
            padding: 4px 10px 4px 10px;
            border-radius: 22px;
            color: $cl-system-error;
          }
        }
      }
    }

    .btn-right {
      > button {
        margin-right: 10px;
      }
    }
  }
}
