@use "sass:map";
@import "src/styles/common/font";
@import "src/styles/common/color";

.feature-root {
  .feature-tree {
    font-weight: map-get($font-weight, "semi-bold");
    font-size: map-get($font-size, "18");
    background: $cl-fill-1;
    position: relative;
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
    padding: 18px;
    color: $cl-lbl-1;
    border-bottom-width: 1px;
    border-bottom-color: #E7E7ED;
    border-bottom-style: solid;
  }

  .ant-tree-checkbox-indeterminate > .ant-tree-checkbox-inner:after {
    background-color: $cl-primary !important;
  }

  .ant-tree-checkbox-checked > .ant-tree-checkbox-inner {
    background-color: $cl-primary !important;
    border-color: $cl-primary !important;
  }
}