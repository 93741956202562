@use "sass:map";
@import "src/styles/common/font";
@import "src/styles/common/color";

.header {
  background-color: $cl-lbl-5;
  padding: 18px 56px 18px 56px;
  border-bottom: 1px solid $cl-separator;
  justify-content: space-between;
  display: flex;
  align-items: center;

  .comment {
    font-weight: 400;
    font-size: 14px;
    color: $cl-lbl-3;
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.login-card {
  background: var(--login-card-bg);
  width: 500px;
  position: relative;
  border-radius: 14px;
  overflow: hidden;
}

.login-card-mobile {
  display: none;
}

.img-login {
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  background-color: #8bc6ec;
  background-image: linear-gradient(135deg, #8bc6ec 0%, var(--core) 100%);
}

.form-login {
  padding: 32px;
}

.form-login h2 {
  color: $cl-lbl-1;
  font-weight: 600;
  font-size: 22px;
}

.registerMessage {
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}

.registerMessage .btn-register {
  margin: 20px auto;
  background: inherit;
  border-radius: 25px;
  width: 50%;
  padding: 12px 0;
  color: white;
  cursor: pointer;
  border: 1px solid white;
}

.registerMessage .btn-register:hover {
  color: var(--btn-register);
  border: 1px solid var(--btn-register);
}

.btn-login {
  background-color: var(--Primary);
  padding: 11px 24px 11px 24px;
  width: 100%;
  border-radius: 10px;
  transition: all ease 0.5s;
  color: white;
  cursor: pointer;
  border: none;
  font-size: 15px;
  font-weight: 600;
  margin-top: 24px;
}

.btn-login:hover {
  background: var(--Primary);
  opacity: 0.5;
}

.container-login {
  background: linear-gradient(180deg, #f3f3f5 0%, #dcffea 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .ellipse-left {
    position: absolute;
    left: 0;
    top: 20%;
  }
  .ellipse-top {
    position: absolute;
    top: 0;
    right: 0;
  }
  .ellipse-right {
    position: absolute;
    top: 40%;
    right: 0;
  }
}

.overlay-1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.form-login .form-item {
  position: relative;
  margin-top: 24px;
}

.form-login .form-item label svg {
  font-size: 18px;
}

.form-login .form-item .form-input {
  padding: 12px 16px 12px 16px;
  border-radius: 10px;
  border-color: var(--sep-01);
}

.title-form {
  font-size: 15px;
  font-weight: 600;
  margin-right: auto;
}

.forgot-password {
  font-size: 13px;
  color: $cl-lbl-2;
  text-decoration: underline;
  cursor: pointer;
}

.body-form {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

.account {
  font-size: 16px;
  font-weight: 400;
}

.register {
  font-size: 15px;
  font-weight: 600;
  color: var(--Primary);
  cursor: pointer;
}

.footer {
  background-color: $cl-lbl-5;
  padding: 18px 56px 18px 56px;
  border-top: 1px solid $cl-separator;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;

  .footer-text {
    font-size: 13px;
    font-weight: 300;
    color: $cl-lbl-2;
  }
}

.container-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media (max-width: 768px) {
  .container-login {
    display: block;
    padding: 0px;
  }

  .login-card {
    background: var(--login-card-bg);
    width: auto;
    top: 0;
    left: 0;
    transform: translate(0, 0);
    position: inherit;
    border-radius: 14px;
    overflow: hidden;

    > .login-card-mobile {
      display: flex;
      justify-content: center;
      margin-top: 40px;
      margin-bottom: 28px;
    }
  }

  .form-login {
    padding: 16px !important;
  }
}
