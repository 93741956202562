.register-card {
  background: white;
  width: 500px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 14px;
  padding: 32px;
}

.container-register {
  z-index: -1;
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay-1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.register-card .row-wrap {
  display: flex;
  gap: 3rem;
}

.register-card .col-wrap {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

/* .form-register{
    margin: 50px 0;
} */

.form-register .form-item {
  position: relative;
}

.form-register h2 {
  color: #292d34;
  font-weight: 600;
  font-size: 22px;
}

.form-register .form-item label.label-form {
  position: absolute;
  top: 35px;
  left: 15px;
  z-index: 900;
  opacity: 0.5;
}

.form-register .form-item label svg {
  font-size: 18px;
}

.form-register .form-item .form-input {
  border-radius: 10px;
  padding: 10px 16px 10px 16px;
  border: 1px solid #e7e7ed;
}
.form-register .form-item .form-input-custom input {
  border-radius: 0 25px 25px 0;
}
.form-register .form-item .form-input-area {
  border-radius: 15px;
}
.form-register .form-item .form-input-custom .ant-select-selector,
.form-register .form-item .form-input-custom {
  border-radius: 10px;
  padding: 10px 16px 10px 16px;
  border: 1px solid #e7e7ed;
}

.title-form {
  font-weight: 600;
  font-size: 15px;
}

.btn-register {
  background-color: var(--Primary);
  padding: 11px 24px 11px 24px;
  width: 100%;
  border-radius: 10px;
  transition: all ease 0.5s;
  color: white;
  cursor: pointer;
  border: none;
  font-size: 15px;
  font-weight: 600;
  margin-top: 16px;
}

.form-register .ant-form-item {
  margin-bottom: 16px;
}

.footer-register {
  font-size: 14px;
  font-weight: 400;
  color: #77787b;
}

.login {
  display: flex;
  justify-content: center;
  gap: 8px;

  .loginText {
    color: #2DC56A;
    cursor: pointer;
    font-weight: 600;
  }
}


@media (max-width: 768px) {
  .register-card {
    background: var(--login-card-bg);
    width: auto;
    top: 0;
    left: 0;
    transform: translate(0, 0);
    position: inherit;
    border-radius: 14px;
    overflow: hidden;
    box-shadow: none;
    padding: 0px 16px 82px 16px;

    >.login-card-mobile{
        display: flex;
        justify-content: center;
        margin-top: 40px;
        margin-bottom: 28px;
    }
  }

  .form-login {
    padding: 16px;
  }
}